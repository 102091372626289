<template>
  <div class="max-w-7xl mx-auto mb-2 bg-white p-4">
    <h1 class="bg-brand text-white py-2 rounded-md text-2xl mb-2">Feedback</h1>
    <div class="flex mb-4">
      <div
        class="w-44 text-left bg-blue-500 hover:bg-blue-700 rounded-md py-2 px-4 text-white text-sm"
      >
        <router-link to="/admin/feedbacks/create">Question Create</router-link>
      </div>
    </div>
    <div class="text-left flex justify-between gap-4">
      <div class="flex flex-col text-left w-full">
        <label for="">Type</label>
        <Select
          v-model="type"
          class="border focus:outline-none"
          @change="onchange"
        >
          <option value="0" class="p-2">Patient</option>
          <option value="1" class="p-2">Mentor</option>
        </Select>
      </div>
      <div>
        <form @submit.prevent="findUserRating()">
          <div class="flex items-center mb-4 gap-2">
            <div class="flex gap-4">
              <div class="flex flex-col text-left">
                <label for="">To</label>
                <InputArea
                  type="date"
                  v-model="form.to"
                  class="border focus:outline-none"
                  required
                />
              </div>
              <div class="flex flex-col text-left">
                <label for="">From</label>
                <InputArea
                  type="date"
                  v-model="form.from"
                  class="border focus:outline-none"
                  required
                />
              </div>
            </div>
            <div class="flex flex-col text-left">
              <label for="">User</label>
              <InputArea
                type="text"
                v-model="form.user"
                placeholder="Search..."
                class="border focus:outline-none"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              class="p-2 bg-green-400 hover:bg-green-600 text-white rounded-md py-1.5 mb-2"
            >
              Search
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="">
      <div class="grid grid-cols-2">
        <div
          class="relative before:w-px before:h-full before:bg-gray-400 before:absolute before:left-full before:-translate-x-1/2 before:bottom-0"
        >
          <div
            v-for="(list, index) in lists"
            :key="index"
            class="bg-white border-b last:border-0 text-left"
          >
            <div v-for="(question, index) in list.questions" :key="index">
              {{ question }}
              <div v-for="(index, key) in list.rating_ratio" :key="key">
                <div
                  @click="findUser(index)"
                  class="cursor-pointer flex items-center gap-2"
                >
                  <Rating :modelValue="key" />
                  <!-- {{ index }} -->
                  <!-- {{ ((index / lists.count) * 100).toFixed(2) }}% -->
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div>
          <div v-for="(list, key) in lists" :key="key">
            <div
              v-for="user_rating in list.user_ratings"
              :key="user_rating"
              class="flex p-2 gap-2 bg-brand text-white rounded-r-md justify-between items-center mb-2"
            >
              <div>
                <div>
                  {{ user_rating.user.name }}
                </div>
                <div>
                  {{ user_rating.user.phone }}
                </div>
              </div>
              <div class="flex gap-2">
                <div>
                  <star-rating
                    :rating="
                      (sum = user_rating.sum_ratings.reduce(
                        (a, b) => a + b,
                        0
                      )) /
                      user_rating.count /
                      list.questions.length
                    "
                    :round-start-rating="false"
                    :star-size="20"
                    :read-only="true"
                    :show-rating="false"
                  />
                </div>
                |
                <div>Total Feedback({{ user_rating.count }})</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rating from "./Rating.vue";
import InputArea from "../../../components/input-new.vue";
import Select from "../../../components/select.vue";
import StarRating from "vue-star-rating";

export default {
  components: {
    Rating,
    InputArea,
    Select,
    StarRating,
  },
  data() {
    return {
      type: 0,
      rating: "",
      form: {
        to: "",
        from: "",
        user: "",
      },
    };
  },

  created() {
    this.$store.dispatch("adminFeedback/getFeedbacks", {
      type: this.type,
    });
  },
  computed: {
    lists() {
      return this.$store.state.adminFeedback.feedbacks.data || "";
    },
  },
  methods: {
    findUser(index) {
      console.log(index);
    },
    findUserRating() {
      console.log(this.form);
    },
    onchange() {
      this.$store.dispatch("adminFeedback/getFeedbacks", {
        type: this.type,
      });
    },
  },
};
</script>

<style></style>
