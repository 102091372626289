<template>
  <div class="text-sm">
    <div class="flex gap-2">
      <StarIcon
        v-for="star in 5"
        :key="star"
        class="cursor-pointer h-6 w-6"
        :class="{
          'text-red-500': star <= modelValue,
          'text-gray-300 hidden': star > modelValue,
        }"
      />
    </div>
  </div>
</template>

<script>
import { StarIcon } from "@heroicons/vue/solid";
export default {
  components: {
    StarIcon,
  },
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
    numberOfStar: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    setRating(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
